import React, { useContext, useEffect, useRef } from "react"
import styled from "styled-components"
import * as moment from "moment"
import "moment/locale/fr"
import { Link } from "gatsby"

import { colors, mq } from "utils/styles"
import I18nContext from "utils/i18nContext"
import { useSiteContent } from "hooks/useSiteContent"
import Tags from "components/Tags"
import RemoveFavoriButton from "../user/profile/Favoris/RemoveFavoriButton"
import { makeLocalePath } from "../../utils/localization"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`
const Anchor = styled.div`
  position: absolute;
  top: -75px;
`
const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0.5em;
  margin-left: calc(50px + 0.5em);
  margin-bottom: 2rem;

  ${mq.large} {
    flex-direction: column;
    align-items: flex-start;
  }
`
const Content = styled.div`
  flex: 1;
  margin-right: 1em;

  a {
    text-decoration: none;
  }
`
const Title = styled.h3`
  margin: 0 0 0.4em;
  cursor: pointer;
`
const Action = styled.div`
  position: relative;
`
const Time = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-right: 0.5em;
  font-family: "GT Walsheim Pro Cond", serif;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.main};
  text-align: center;
  text-transform: uppercase;
  border: 2px solid ${colors.main};
  border-radius: 50%;
`
const Heure = styled.span`
  position: absolute;
  bottom: 0;
  transform: translateY(150%);
  color: ${colors.main};
`
const Venue = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  a {
    display: flex;
    text-decoration: none;
  }

  svg {
    margin-right: 0.5rem;
    margin-bottom: calc(5px + 0.3em);
    fill: ${colors.main};
  }
`
const VenueTitle = styled.h4`
  margin: 0;
  color: #fff;
  font-weight: 400;
`

const MaybeLinkMaybeDiv = ({
  children,
  venue,
  locale,
  changeCurrentVenue,
  setEventId,
  event,
  toggleOpenFilters,
}) => {
  if (changeCurrentVenue) {
    return (
      <div
        onClick={() => {
          changeCurrentVenue(venue)
          setEventId(event.id)
          toggleOpenFilters(prevOpen => !prevOpen)
        }}
      >
        {children}
      </div>
    )
  } else {
    return (
      <Link
        to={
          makeLocalePath(venue.type, venue.slug, locale) + "/?event=" + event.id
        }
      >
        {children}
      </Link>
    )
  }
}

export default function SmallEvent({
  event,
  currentEvent,
  displayDesc = true,
  displayAction = true,
  locale,
  changeCurrentVenue,
  setEventId,
  toggleOpenFilters,
}) {
  const { lang } = useContext(I18nContext)
  moment.locale(lang)
  const { lives, clubs, studios, records } = useSiteContent()
  const venues = lives.concat(clubs, studios, records)

  let venue
  if (event.acf.lieu?.nodes && event.acf.lieu.nodes.length > 0) {
    venue = venues.filter(venue => venue.id === event.acf.lieu.nodes[0].id)
    venue = venue[0]
  }

  const scrollAnchor = useRef(null)

  useEffect(() => {
    const scrollAnchorEl = scrollAnchor.current
    if (parseInt(currentEvent) === parseInt(event.id)) {
      scrollAnchorEl.scrollIntoView()
    }
  }, [currentEvent, event.id])

  let strippedDescription = event.acf?.description
    .replace(/(<([^>]+)>)/gi, "")
    .substring(0, 150)

  return (
    <>
      <Wrapper>
        <Anchor ref={scrollAnchor} id={"event_" + event.id} />
        <Time>
          {moment(event.acf.horaires.debut).format("ddd DD MMM")}
          <Heure>{moment(event.acf.horaires.debut).format("HH:mm")}</Heure>
        </Time>
        <Content>
          {venue && (
            <MaybeLinkMaybeDiv
              venue={venue}
              locale={locale}
              changeCurrentVenue={changeCurrentVenue}
              setEventId={setEventId}
              event={event}
              toggleOpenFilters={toggleOpenFilters}
            >
              <Title
                dangerouslySetInnerHTML={{
                  __html: event.title,
                }}
              />
            </MaybeLinkMaybeDiv>
          )}
          {displayDesc && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: strippedDescription,
                }}
              />
              {"..."}
            </>
          )}
        </Content>
        {displayAction && (
          <Action>
            <RemoveFavoriButton toDelete={event} />
          </Action>
        )}
      </Wrapper>
      <Footer>
        {venue && (
          <Venue>
            <Link to={makeLocalePath(venue.type, venue.slug, locale)}>
              <svg width="13px" height="19px">
                <path
                  fill="rgb(255, 255, 255)"
                  d="M6.264,18.136 C6.264,18.136 0.375,10.571 0.375,6.999 C0.375,3.426 3.011,0.531 6.264,0.531 C9.516,0.531 12.153,3.426 12.153,6.999 C12.153,10.571 6.264,18.136 6.264,18.136 ZM6.420,4.419 C5.254,4.419 4.308,5.371 4.308,6.545 C4.308,7.719 5.254,8.670 6.420,8.670 C7.587,8.670 8.532,7.719 8.532,6.545 C8.532,5.371 7.587,4.419 6.420,4.419 Z"
                />
              </svg>
              <VenueTitle
                dangerouslySetInnerHTML={{
                  __html: venue.title,
                }}
              />
            </Link>
          </Venue>
        )}
        <Tags element={event} />
      </Footer>
    </>
  )
}
