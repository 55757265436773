import React, { useContext, useEffect, useRef, useState } from "react"
import { login } from "../../services/auth"
import { Link, navigate } from "gatsby"
import UserLayout from "./UserLayout"
import UserContext from "../../utils/UserContext"
import { Error, Field, Footer, Form, Reset, Submit, Title } from "../form/form"
import styled from "styled-components"
import { colors, mq } from "../../utils/styles"
import { makeLocalePath } from "../../utils/localization"
import I18nContext from "../../utils/i18nContext"

const Columns = styled.div`
  display: grid;
  gap: 3rem;
  grid-auto-flow: row;
  grid-auto-columns: 1fr;
  max-width: 100%;

  ${mq.medium} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
  }
`

const Column = styled.div`
  max-width: 100%;

  form {
    width: auto;
    max-width: 100%;
  }

  ${mq.medium} {
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(5em, 25em));
  }
`

const InscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;

  h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #fff;
    font-weight: 500;
  }

  hr {
    width: 100%;
    margin: 0;
    background: #fff;
    height: 2px;
    border: none;
  }
`

const Button = styled(props => <Link {...props} />)`
  display: inline-flex;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border-radius: 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 14px;
  background: ${colors.background};
  color: #fff;
  border: 2px solid #fff;
`

export default function Login({ locale, pageContext }) {
  const { isLoggedIn, setIsLoggedIn, setProfile } = useContext(UserContext)
  const { translate } = useContext(I18nContext)
  const [credentials, setCredentials] = useState({
    email: ``,
    password: ``,
  })
  const loginButton = useRef(null)
  const [displayLoginError, setDisplayLoginError] = useState("")

  const handleUpdate = e => {
    setCredentials(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleLogin = async ({ email, password }) => {
    const response = await login(email, password)
    const result = response.data
    loginButton.current.classList.add("loading")

    if (!result.errors || result.errors.length === 0) {
      // Success
      setIsLoggedIn(true)
      setProfile(result.data.loginFromFrontend.user)
    } else {
      // Error
      loginButton.current.classList.remove("loading")
      switch (result.errors[0].message) {
        case "invalid_username":
          setDisplayLoginError(translate("Erreur : Email invalide"))
          break
        case "invalid_email":
          setDisplayLoginError(translate("Erreur : Email invalide"))
          break
        case "incorrect_password":
          setDisplayLoginError(translate("Erreur : Mot de passe erroné"))
          break

        default:
          setDisplayLoginError(translate("Erreur : Une erreur est survenue."))
          break
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    handleLogin(credentials).then(() => {
      if (isLoggedIn) {
        navigate(makeLocalePath("user", "profile", locale))
      }
    })
  }

  const handleResetPassword = e => {
    e.preventDefault()
    navigate(makeLocalePath("user", "resetpassword", locale))
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate(makeLocalePath("user", "profile", locale))
    }
  }, [isLoggedIn, locale])

  return (
    <UserLayout locale={locale} pageContext={pageContext}>
      <Columns>
        <Column>
          <Form method="post" onSubmit={handleSubmit}>
            <Title>{translate(`Connexion`)}</Title>
            <Field>
              <label htmlFor="email">{translate(`Email`)}</label>
              <input
                type="text"
                name="email"
                id="email"
                required
                onChange={handleUpdate}
              />
            </Field>
            <Field>
              <label htmlFor="password">{translate(`Mot de passe`)}</label>
              <input
                type="password"
                name="password"
                id="password"
                required
                onChange={handleUpdate}
              />
            </Field>
            <Footer>
              <Reset onClick={handleResetPassword}>
                {translate(`Mot de passe oublié ?`)}
              </Reset>
              <Submit type="submit" ref={loginButton}>
                {translate(`Connexion`)}
              </Submit>
            </Footer>
            {displayLoginError && <Error>{displayLoginError}</Error>}
          </Form>
        </Column>
        <Column>
          <InscriptionWrapper>
            <hr />
            <h4>{translate(`Pas encore de compte ?`)}</h4>
            <Button to={makeLocalePath("user", "register", locale)}>
              {translate(`Inscription`)}
            </Button>
          </InscriptionWrapper>
        </Column>
      </Columns>
    </UserLayout>
  )
}
