import { register } from "../../services/auth"
import React, { useContext, useRef, useState } from "react"
import UserLayout from "./UserLayout"
import UserContext from "../../utils/UserContext"
import { Error, Field, Footer, Form, Submit, Title } from "../form/form"
import styled from "styled-components"
import { mq } from "../../utils/styles"
import { Link, navigate } from "gatsby"
import { makeLocalePath } from "../../utils/localization"
import I18nContext from "../../utils/i18nContext"

const Box = styled.div`
  border: 1px solid #fff;
  padding: 2rem;
  margin: 2rem auto;
  width: 90%;

  h3 {
    color: #fff;
    margin-bottom: 0.5rem;
  }

  ${mq.medium} {
    width: 500px;
    max-width: 80%;
  }
`

const Register = ({ locale, pageContext }) => {
  const { setIsLoggedIn, setProfile } = useContext(UserContext)
  const { translate } = useContext(I18nContext)
  const registerButton = useRef(null)
  const [displayRegisterError, setDisplayRegisterError] = useState("")
  const [values, setValues] = useState({
    nom: ``,
    prenom: ``,
    email: ``,
    password: ``,
  })

  const handleUpdate = e => {
    setValues(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    register(values.prenom, values.nom, values.email, values.password).then(
      e => {
        const result = e.data
        if (!result.errors || result.errors.length === 0) {
          // Success
          setIsLoggedIn(true)
          setProfile(result.data.registerFromFrontend.user)
          setDisplayRegisterError(
            translate(
              "Vous êtes bien inscrit·e. Vous allez être redirigé·e vers votre compte."
            )
          )
          registerButton.current.classList.remove("loading")
          setTimeout(() => {
            navigate(makeLocalePath("user", "profile", locale))
          }, 2500)
        } else {
          registerButton.current.classList.remove("loading")
          setDisplayRegisterError(translate("Une erreur est survenue."))
        }
      }
    )
  }

  return (
    <UserLayout locale={locale} pageContext={pageContext}>
      <Form method="post" onSubmit={handleSubmit}>
        <Title>{translate(`Inscription`)}</Title>
        <Field>
          <label htmlFor="prenom">{translate(`Prénom`)}</label>
          <input
            type="text"
            name="prenom"
            id="prenom"
            value={values.prenom}
            required
            onChange={handleUpdate}
          />
        </Field>
        <Field>
          <label htmlFor="email">{translate(`Nom`)}</label>
          <input
            type="text"
            name="nom"
            id="nom"
            value={values.nom}
            required
            onChange={handleUpdate}
          />
        </Field>
        <Field>
          <label htmlFor="email">{translate(`Email`)}</label>
          <input
            type="text"
            name="email"
            id="email"
            value={values.email}
            required
            onChange={handleUpdate}
          />
        </Field>
        <Field>
          <label htmlFor="password">{translate(`Mot de passe`)}</label>
          <input
            type="password"
            name="password"
            id="password"
            value={values.password}
            required
            onChange={handleUpdate}
          />
        </Field>
        <Field>
          <label htmlFor="rgpd">
            <input
              type="checkbox"
              name="rgpd"
              id="rgpd"
              value={values.rgpd}
              required
              onChange={handleUpdate}
            />
            <Link
              to={makeLocalePath(
                "page",
                {
                  fr: "mentions-legales",
                  en: "terms",
                },
                locale
              )}
            >
              {translate(`J'accepte la politique de confidentialité`)}
            </Link>
          </label>
        </Field>
        <Footer>
          <Submit type="submit" ref={registerButton}>
            {translate(`Inscription`)}
          </Submit>
        </Footer>
        {displayRegisterError && <Error>{displayRegisterError}</Error>}
      </Form>

      <Box>
        <h3>{translate(`Vous avez déjà un compte ?`)}</h3>
        <Link to={makeLocalePath("user", "login", locale)}>
          {translate(`Cliquez-ici pour vous connecter`)}
        </Link>
      </Box>
    </UserLayout>
  )
}

export default Register
