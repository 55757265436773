import React from "react"
import styled from "styled-components"

import { colors } from "utils/styles"

const Wrapper = styled.div`
  margin: 0 0.3em 0.3em 0;
  padding: 0.3em;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.background};
  background-color: ${colors.beige};
  border: 1px solid ${colors.beige};
`
export default function Tag(props) {
  return <Wrapper>{props.children}</Wrapper>
}
