import { navigate } from "gatsby"
import React, { useContext } from "react"
import UserContext from "utils/UserContext"
import { makeLocalePath } from "../../utils/localization"

export const PrivateRoute = ({
  component: Component,
  location,
  locale,
  ...rest
}) => {
  const { isLoggedIn } = useContext(UserContext)
  if (
    !isLoggedIn &&
    location.pathname !== makeLocalePath("user", "login", locale)
  ) {
    navigate(makeLocalePath("user", "login", locale))
    return null
  }

  return <Component locale={locale} {...rest} />
}
