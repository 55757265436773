import styled, { keyframes } from "styled-components"
import { colors, mq } from "../../utils/styles"

export const Title = styled.h1`
  margin-bottom: 1rem;
  text-align: left;
`
export const Form = styled.form`
  margin: auto;
  border: 1px solid ${colors.main};
  padding: 2rem;
  width: 90%;

  ${mq.medium} {
    width: 500px;
    max-width: 80%;
  }
`
export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  label {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }

  input {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(0.5em - 1px) calc(0.75em - 1px);
    position: relative;
    vertical-align: top;
    background-color: white;
    border: none;
    border-radius: 0;
    color: #363636;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
  }

  input[type="checkbox"] {
    width: auto;
    margin-right: 1rem;
  }
`
export const FieldLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-bottom: 2rem;
  ${props =>
    props.noWrap
      ? `
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  `
      : null};

  label {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 0;
  }

  > input {
    align-items: center;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    justify-content: flex-start;
    line-height: 1.5;
    padding: calc(0.5em - 1px) calc(0.75em - 1px);
    position: relative;
    vertical-align: top;
    background-color: white;
    border: none;
    border-radius: 0;
    color: #363636;
    box-shadow: none;
    min-width: 0;
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  ${mq.large} {
    align-items: ${props =>
      props.alignItems === "start" ? "flex-start" : "center"};
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 2rem;

    > input {
      width: auto;
      min-width: 250px;
      margin-left: 1rem;
    }
  }
`

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  flex-basis: 40px;
  flex-shrink: 0;
  height: 22px;

  /* Hide default HTML checkbox */

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: ${colors.main};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${colors.main};
  }

  input:checked + .slider:before {
    transform: translateX(17px);
  }

  /* Rounded sliders */

  .slider.round {
    border-radius: 22px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`

export const FormGroup = styled.div`
  display: block;
  margin-bottom: 3rem;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const Reset = styled.a`
  margin-right: 1rem;
  border: none;
  background: transparent;
  cursor: pointer;
`

const rotate = keyframes`
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
`

export const Submit = styled.button`
  position: relative;
  cursor: pointer;
  border-radius: 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 14px;
  background: ${colors.background};
  color: ${colors.main};
  border: 2px solid ${colors.main};

  &:hover {
    background: ${colors.main};
    color: ${colors.background};
  }

  &.loading {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${colors.background};
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1rem;
      height: 1rem;
      border-radius: 1rem;
      border-top: 2px solid ${colors.main};
      border-right: 2px solid ${colors.main};
      border-bottom: 2px solid transparent;
      border-left: 2px solid transparent;
      animation: ${rotate} 500ms linear infinite;
      background: ${colors.background};
    }
  }
`

export const Success = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  width: calc(100% + 4rem);
  margin: 1rem -2rem -2rem;
  padding: 1rem;
  color: #ff3d38;
  background: rgba(0, 0, 0, 0.5);
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  width: calc(100% + 4rem);
  margin: 1rem -2rem -2rem;
  padding: 1rem;
  color: #ff3d38;
  background: rgba(0, 0, 0, 0.5);
`

export const LineMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  padding: 1rem;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
  margin-top: 1rem;
`
