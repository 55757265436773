import React, { useContext } from "react"
import { useSiteContent } from "../../../../hooks/useSiteContent"
import styled from "styled-components"
import Tag from "./Tag"
import { colors } from "../../../../utils/styles"
import I18nContext from "../../../../utils/i18nContext"

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.3rem;
`

const ListWrapper = styled.div`
  //margin-left: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.75rem;
  border: 1px solid ${colors.blue};
  border-top: none;
`

const ListTitle = styled.p`
  background: #121469;
  padding: 0.75rem 2rem 0.75rem 2rem;
  margin-left: -2rem;
  margin-right: -2rem;
`

export default function EventFilters() {
  const { genres } = useSiteContent()
  const { translate } = useContext(I18nContext)

  return (
    <ListWrapper>
      <ListTitle>
        {translate(
          `Choisis le type d'événement que tu ne veux surtout pas manquer`
        )}
      </ListTitle>
      <List>
        {genres.map(tag => (
          <Tag key={tag.id} tag={tag} />
        ))}
      </List>
    </ListWrapper>
  )
}
