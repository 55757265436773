import { resetUserPassword, sendPasswordResetEmail } from "../../services/auth"
import React, { useContext, useEffect, useRef, useState } from "react"
import UserLayout from "./UserLayout"
import UserContext from "../../utils/UserContext"
import { navigate } from "gatsby"
import { Field, Footer, Form, Submit, Success, Title } from "../form/form"
import I18nContext from "../../utils/i18nContext"

const ResetPassword = ({ locale, pageContext }) => {
  const { isLoggedIn } = useContext(UserContext)
  const { translate } = useContext(I18nContext)
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  const isUpdateForm = params.username && params.key
  const resetPassButton = useRef(null)
  const updatePassButton = useRef(null)
  const [displayResetSuccess, setDisplayResetSuccess] = useState("")
  const [displayUpdateSuccess, setDisplayUpdateSuccess] = useState("")

  const [values, setValues] = useState({
    email: "",
    key: params.key,
    username: params.username,
    password: "",
  })

  const handleUpdate = e => {
    setDisplayResetSuccess("")
    setDisplayUpdateSuccess("")
    setValues(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (isUpdateForm) {
      /*
       * UPDATE PASSWORD
       */
      updatePassButton.current.classList.add("loading")
      resetUserPassword(values.key, values.username, values.password).then(
        e => {
          const result = e.data
          if (!result.errors || result.errors.length === 0) {
            // Success
            setDisplayUpdateSuccess(
              translate(
                "Votre mot de passe à bien été modifié, vous allez être redirigé·e vers la page de connexion."
              )
            )
            updatePassButton.current.classList.remove("loading")
            setTimeout(() => {
              navigate("/user/login")
            }, 2500)
          } else {
            updatePassButton.current.classList.remove("loading")
            setDisplayUpdateSuccess(translate("Une erreur est survenue."))
          }
        }
      )
    } else {
      /*
       * RESET EMAIL
       */
      resetPassButton.current.classList.add("loading")
      sendPasswordResetEmail(values.email).then(e => {
        const result = e.data
        if (!result.errors || result.errors.length === 0) {
          // Success
          setDisplayResetSuccess(
            translate(
              "Un lien de renouvellement de mot de passe à été envoyé à cet email."
            )
          )
          resetPassButton.current.classList.remove("loading")
        } else {
          resetPassButton.current.classList.remove("loading")
          switch (result.errors[0].message) {
            case "Invalid username.":
              setDisplayResetSuccess(translate("Erreur : Email invalide"))
              break

            default:
              setDisplayResetSuccess(
                translate("Erreur : Une erreur est survenue.")
              )
              break
          }
        }
      })
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate(`/user/profile`)
    }
  }, [isLoggedIn])

  return (
    <UserLayout locale={locale} pageContext={pageContext}>
      <Form method="post" onSubmit={handleSubmit}>
        {isUpdateForm ? (
          <>
            <Title>{translate(`Changer de mot de passe`)}</Title>
            <Field>
              <label htmlFor="password">
                {translate(`Nouveau mot de passe`)}
              </label>
              <input
                type="text"
                name="password"
                id="password"
                value={values.password}
                onChange={handleUpdate}
              />
            </Field>
            <Footer>
              <Submit type="submit" ref={updatePassButton}>
                {translate(`Mettre à jour le mot de passe`)}
              </Submit>
            </Footer>
            {displayUpdateSuccess && <Success>{displayUpdateSuccess}</Success>}
          </>
        ) : (
          <>
            <Title>{translate(`Mot de passe oublié`)}</Title>
            <Field>
              <label htmlFor="email">{translate(`Email`)}</label>
              <input
                type="text"
                name="email"
                id="email"
                required
                value={values.email}
                onChange={handleUpdate}
              />
            </Field>
            <Footer>
              <Submit type="submit" ref={resetPassButton}>
                {translate(`Réinitialiser le mot de passe`)}
              </Submit>
            </Footer>
            {displayResetSuccess && <Success>{displayResetSuccess}</Success>}
          </>
        )}
      </Form>
    </UserLayout>
  )
}

export default ResetPassword
