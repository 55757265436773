import React, { useContext, useState } from "react"
import UserLayout from "./UserLayout"
import UserContext from "../../utils/UserContext"
import styled from "styled-components"
import Preferences from "./profile/Preferences"
import InformationsPersonnelles from "./profile/Reglages"
import { colors, mq } from "../../utils/styles"
import { logout } from "../../services/auth"
import { navigate } from "gatsby"
import useWindowSize from "../../hooks/useWindowSize"
import { window } from "browser-monads"
import { makeLocalePath } from "../../utils/localization"
import I18nContext from "../../utils/i18nContext"

const Columns = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 1fr;

  ${mq.medium} {
    grid-template-columns: 17rem 1fr;
  }
`

const Sidebar = styled.div`
  background: ${colors.background};
  padding: 2rem 1rem;
  border-right: 1px solid #121469;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const MainColumn = styled.div`
  padding: 2rem;
  background: ${colors.background};
`

const MenuTitle = styled.h3`
  color: #e5e5e5;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;
`

const Menu = styled.div`
  display: flex;

  ${mq.medium} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 2rem;
  }
`

const MenuElement = styled.div`
  display: block;
  padding: 1rem;
  background: ${props => (props.active ? "#02052a" : colors.background)};
  margin-bottom: 0.25rem;
  cursor: pointer;
  color: ${props => (props.active ? "#fff" : "#e5e5e5")};
  border-radius: 0.25rem;
  font-size: 14px;
  text-transform: uppercase;

  &:hover {
    background: #02052a;
    color: #fff;
  }
`

const LogoutButton = styled.button`
  position: relative;
  cursor: pointer;
  border-radius: 0;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  font-size: 14px;
  background: ${colors.background};
  color: #fff;
  border: 1px solid #fff;
  margin: auto auto 0 1rem;

  &:hover {
    background: #fff;
    color: ${colors.background};
  }
`

const LogoutInline = styled.span`
  cursor: pointer;
  text-decoration: underline;
`

const Profile = ({ locale, pageContext, events }) => {
  const { setIsLoggedIn, profile } = useContext(UserContext)
  const { translate } = useContext(I18nContext)
  const [page, setPage] = useState("preferences")
  const windowSize = useWindowSize()

  const handleLogout = () => {
    logout().then(() => {
      setIsLoggedIn(false)
      navigate(makeLocalePath("user", "login", locale))
    })
  }

  // Redirect if is a pro
  if (profile.role === "structure_pro" || profile.role === "curateur") {
    window.location = "https://api.musicinparis.fr/"
  }

  return (
    <UserLayout locale={locale} pageContext={pageContext}>
      <Columns>
        <Sidebar>
          <MenuTitle>
            {profile.nomComplet}
            {windowSize.width <= 640 && (
              <>
                {" "}
                (
                <LogoutInline onClick={handleLogout}>
                  {translate(`Déconnexion`)}
                </LogoutInline>
                )
              </>
            )}
          </MenuTitle>
          <Menu>
            <MenuElement
              onClick={() => setPage("preferences")}
              active={page === "preferences"}
            >
              {translate(`Préférences`)}
            </MenuElement>
            <MenuElement
              onClick={() => setPage("informations_personnelles")}
              active={page === "informations_personnelles"}
            >
              {translate(`Réglages`)}
            </MenuElement>
            {(profile.role === "curator" ||
              profile.role === "structure_pro") && (
              <MenuElement
                onClick={() =>
                  (window.location = "https://api.musicinparis.fr/")
                }
              >
                {translate(`Espace Pro`)}
              </MenuElement>
            )}
          </Menu>
          {windowSize.width >= 640 && (
            <LogoutButton onClick={handleLogout}>
              {translate(`Déconnexion`)}
            </LogoutButton>
          )}
        </Sidebar>
        <MainColumn>
          {page === "preferences" && (
            <Preferences locale={locale} events={events} />
          )}
          {page === "informations_personnelles" && <InformationsPersonnelles />}
        </MainColumn>
      </Columns>
    </UserLayout>
  )
}

export default Profile
