import React from "react"
import styled from "styled-components"

import Tag from "./tags/Tag"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${props => (props.all ? "" : "max-height: calc(3.8em + 4px)")};
  margin-bottom: ${props => (props.all ? "1rem" : "5px")};
  font-size: 10px;
  overflow: hidden;
`

const filterTagsForCategory = (tags, category) => {
  return tags.filter(tag => {
    // Si pas caché du tout OU pas caché pour cette catégorie on affiche le tag
    if (
      !category ||
      !tag.acf.cacherPour ||
      (typeof tag.acf.cacherPour === "object" &&
        !tag.acf.cacherPour.includes(category))
    ) {
      return true
    } else {
      return null
    }
  })
}

export default function Tags(props) {
  let moods = props.element.moods ? props.element.moods.nodes : []
  let spaces = props.element.spaces ? props.element.spaces.nodes : []
  let genres = props.element.genres ? props.element.genres.nodes : []
  let activities = props.element.activities
    ? props.element.activities.nodes
    : []

  moods = filterTagsForCategory(moods, props.element.type)
  spaces = filterTagsForCategory(spaces, props.element.type)
  genres = filterTagsForCategory(genres, props.element.type)
  activities = filterTagsForCategory(activities, props.element.type)

  return (
    <Wrapper all={props.all}>
      {moods ? moods.map(tag => <Tag key={tag.id}>{tag.name}</Tag>) : ""}
      {spaces ? spaces.map(tag => <Tag key={tag.id}>{tag.name}</Tag>) : ""}
      {genres ? genres.map(tag => <Tag key={tag.id}>{tag.name}</Tag>) : ""}
      {activities
        ? activities.map(tag => <Tag key={tag.id}>{tag.name}</Tag>)
        : ""}
    </Wrapper>
  )
}
