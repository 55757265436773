import React, { useContext } from "react"
import styled from "styled-components"
import { colors, mq } from "../../utils/styles"
import useWindowSize from "../../hooks/useWindowSize"
import { Link } from "gatsby"
import BackButton from "../misc/BackButton"
import Menu from "../Menu"
import { StaticImage } from "gatsby-plugin-image"
import { makeCurrentLocationObject } from "../../utils/localization"
import I18nContext from "../../utils/i18nContext"

const Wrapper = styled.div`
  position: relative;
  z-index: 120;
  display: grid;
  min-height: ${props =>
    props.windowHeight ? props.windowHeight + "px" : "100dvh"};
  background-color: ${colors.background};
`
const ContentWrapper = styled.div`
  grid-area: 1 / 1;
  position: relative;
  z-index: 1;
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`

const Title = styled.h1`
  margin: 0;
`

const Content = styled.div`
  padding: 1em 1em;

  ${mq.large} {
    width: 800px;
    margin: 0 auto;
  }
`

export const PageTitle = styled.h2`
  color: #fff;
  margin: 0;
`

export const PageHeader = styled.div`
  border-bottom: 1px solid #fff;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
`

export const FormWrapper = styled.section`
  margin-bottom: 3rem;
`

export const FormTitle = styled.h4`
  margin-bottom: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`

export const FormSubTitle = styled.h5`
  margin-bottom: 1rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 1rem;
`

export default function UserLayout({ children, locale, pageContext }) {
  const { translate } = useContext(I18nContext)
  const windowSize = useWindowSize()
  const translations = {
    fr: {
      slug: pageContext.slugs["fr"],
    },
    en: {
      slug: pageContext.slugs["en"],
    },
  }
  const currentLocation = makeCurrentLocationObject("user", translations)

  return (
    <Wrapper windowHeight={windowSize.height}>
      <StaticImage
        style={{
          gridArea: "1/1",
          position: "fixed",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        placeholder="blurred"
        layout="fullWidth"
        quality={90}
        alt=""
        src={"../../img/fond.jpg"}
        formats={["auto", "webp"]}
      />
      <ContentWrapper>
        <Header>
          <Link to="/">
            <BackButton />
          </Link>
          <Title>{translate(`Mon compte`)}</Title>
          <Menu locale={locale} currentLocation={currentLocation} />
        </Header>

        <Content>{children}</Content>
      </ContentWrapper>
    </Wrapper>
  )
}
