import React, { useState } from "react"
import styled from "styled-components"

import { colors } from "utils/styles"
import useUserProfile from "../../../../hooks/useUserProfile"
import { updateEventsFilters } from "../../../../services/auth"

const Wrapper = styled.div`
  margin: 0.3rem;
  padding: 0.4rem 0.8rem;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => (props.active ? colors.background : colors.white)};
  background-color: ${props => (props.active ? colors.main : "transparent")};
  border: 1px solid ${colors.main};
  cursor: pointer;
  transition: all 300ms ease-out;
`
const Icon = styled.span`
  display: inline-block;
  width: 0.6em;
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.active ? colors.background : colors.main)};
`
export default function Tag({ tag }) {
  const [profile, setProfile] = useUserProfile()
  const checkedFiltresSlugs =
    profile.preferences.filtresAlerteEvenement.nodes.map(tag => {
      return tag.id
    })
  const [checked, setChecked] = useState(checkedFiltresSlugs.includes(tag.id))
  const handleUpdate = e => {
    e.preventDefault()
    const action = !checked ? "add" : "delete"
    const revertChecked = checked
    setChecked(!checked)

    updateEventsFilters(action, tag.id).then(e => {
      const result = e.data
      if (!result.errors || result.errors.length === 0) {
        // Success
        setProfile(result.data.updateEventsFilters.user)
      } else {
        // Error
        setChecked(revertChecked)
      }
    })
  }

  return (
    <Wrapper active={checked} onClick={handleUpdate}>
      <Icon active={checked}>{checked ? "-" : "+"}</Icon>{" "}
      <span>{tag.name}</span>
    </Wrapper>
  )
}
