import React, { useState } from "react"
import { FieldLine, Switch } from "../../../form/form"
import styled from "styled-components"
import useUserProfile from "../../../../hooks/useUserProfile"
import { updateUserNotification } from "../../../../services/auth"

const LabelPrincipal = styled.div`
  width: 100%;
`
const LabelDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
`

export default function NotificationToggle({
  id,
  labelDescription,
  labelPrincipal,
  toggleCallback = () => {},
}) {
  const [profile, setProfile] = useUserProfile()
  const [checked, setChecked] = useState(!!profile.preferences[id])

  const handleUpdate = e => {
    e.preventDefault()
    const revertChecked = checked
    setChecked(e.target.checked)
    toggleCallback(e.target.checked)
    updateUserNotification(id, e.target.checked).then(e => {
      const result = e.data
      if (!result.errors || result.errors.length === 0) {
        // Success
        setProfile(result.data.updateUserNotification.user)
      } else {
        // Error
        setChecked(revertChecked)
      }
    })
  }

  return (
    <FieldLine noWrap>
      <label htmlFor={id}>
        <LabelPrincipal>{labelPrincipal}</LabelPrincipal>
        <LabelDescription>{labelDescription}</LabelDescription>
      </label>
      <Switch className="switch">
        <input
          type="checkbox"
          name={id}
          id={id}
          checked={checked}
          onChange={handleUpdate}
          aria-label={labelDescription}
        />
        <span className="slider round" />
      </Switch>
    </FieldLine>
  )
}
