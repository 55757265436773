import React, { useContext, useEffect, useRef, useState } from "react"
import UserContext from "../../../utils/UserContext"
import { FormTitle, FormWrapper, PageHeader, PageTitle } from "../UserLayout"
import { FieldLine, Footer, LineMessage, Submit } from "../../form/form"
import { updateUserInfos, updateUserPassword } from "../../../services/auth"
import I18nContext from "../../../utils/i18nContext"

export default function Reglages() {
  const { profile, setProfile } = useContext(UserContext)
  const { translate } = useContext(I18nContext)
  const submitInfoPersoButton = useRef(null)
  const submitPasswordButton = useRef(null)

  const [infoPerso, setInfoPerso] = useState({
    prenom: profile.prenom ?? "",
    nom: profile.nom ?? "",
    email: profile.email ?? "",
  })
  const [passwords, setPasswords] = useState({
    password1: "",
    password2: "",
  })
  const [displayPasswordMatchError, setDisplayPasswordMatchError] =
    useState(false)
  const [displayPasswordUpdateSuccess, setDisplayPasswordUpdateSuccess] =
    useState(false)
  const [displayInfoPersoUpdateSuccess, setDisplayInfoPersoUpdateSuccess] =
    useState(false)

  const handleInfoPersoUpdate = e => {
    setInfoPerso(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handlePasswordUpdate = e => {
    setDisplayPasswordMatchError(false)
    setPasswords(prevState => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const handleInfoPersoSubmit = e => {
    e.preventDefault()
    submitInfoPersoButton.current.classList.add("loading")

    /*
     * Check if different values from profile
     */
    if (
      infoPerso.nom === profile.nom &&
      infoPerso.prenom === profile.prenom &&
      infoPerso.email === profile.email
    ) {
      submitInfoPersoButton.current.classList.remove("loading")
      return
    }

    updateUserInfos(infoPerso.prenom, infoPerso.nom, infoPerso.email).then(
      e => {
        submitInfoPersoButton.current.classList.remove("loading")
        const result = e.data
        if (!result.errors || result.errors.length === 0) {
          // Success
          setProfile(result.data.updateUserInfos.user)
          setDisplayInfoPersoUpdateSuccess(true)
        } else {
          // Error
          // Todo handle error
        }
      }
    )
  }

  const handlePasswordUpdateSubmit = e => {
    e.preventDefault()
    submitPasswordButton.current.classList.add("loading")

    /*
     * Check if password confirmation matches
     */
    if (passwords.password1 !== passwords.password2) {
      submitPasswordButton.current.classList.remove("loading")

      setDisplayPasswordMatchError(true)
      return
    }

    updateUserPassword(profile.email, passwords.password1).then(e => {
      submitPasswordButton.current.classList.remove("loading")
      const result = e.data
      if (!result.errors || result.errors.length === 0) {
        // Success
        setDisplayPasswordUpdateSuccess(true)
        setPasswords({
          password1: "",
          password2: "",
        })
      } else {
        // Error
        // Todo handle error
      }
    })
  }

  /*
   * Clear success message
   */
  useEffect(() => {
    let clearIds = {}

    if (displayPasswordUpdateSuccess) {
      clearIds.password = setTimeout(() => {
        setDisplayPasswordUpdateSuccess(false)
      }, 3000)
    }

    if (displayInfoPersoUpdateSuccess) {
      clearIds.infos = setTimeout(() => {
        setDisplayInfoPersoUpdateSuccess(false)
      }, 3000)
    }

    return () => {
      clearTimeout(clearIds.password)
      clearTimeout(clearIds.infos)
    }
  }, [displayInfoPersoUpdateSuccess, displayPasswordUpdateSuccess])

  return (
    <>
      <PageHeader>
        <PageTitle>{translate(`Informations personnelles`)}</PageTitle>
      </PageHeader>

      <FormWrapper>
        <FormTitle>{translate(`Modifier mes informations personnelles`)}</FormTitle>
        <form method="post" onSubmit={handleInfoPersoSubmit}>
          <FieldLine>
            <label>{translate(`Prénom`)}</label>
            <input
              type="text"
              name="prenom"
              value={infoPerso.prenom}
              onChange={handleInfoPersoUpdate}
            />
          </FieldLine>
          <FieldLine>
            <label>{translate(`Nom`)}</label>
            <input
              type="text"
              name="nom"
              value={infoPerso.nom}
              onChange={handleInfoPersoUpdate}
            />
          </FieldLine>
          <FieldLine>
            <label>{translate(`Email`)}</label>
            <input
              type="email"
              name="email"
              value={infoPerso.email}
              onChange={handleInfoPersoUpdate}
            />
          </FieldLine>
          <Footer>
            <Submit ref={submitInfoPersoButton}>{translate(`Mettre à jour`)}</Submit>
          </Footer>
          {displayInfoPersoUpdateSuccess && (
            <LineMessage>{translate(`Votre profil a bien été mis à jour`)}</LineMessage>
          )}
        </form>
      </FormWrapper>

      <FormWrapper>
        <FormTitle>{translate(`Modifier mon mot de passe`)}</FormTitle>
        <form method="post" onSubmit={handlePasswordUpdateSubmit}>
          <FieldLine>
            <label>{translate(`Nouveau mot de passe`)}</label>
            <input
              type="password"
              name="password1"
              required
              value={passwords.password1}
              onChange={handlePasswordUpdate}
            />
          </FieldLine>
          <FieldLine>
            <label>{translate(`Confirmer le nouveau mot de passe`)}</label>
            <input
              type="password"
              name="password2"
              required
              value={passwords.password2}
              onChange={handlePasswordUpdate}
            />
          </FieldLine>
          {displayPasswordMatchError && (
            <LineMessage>{translate(`Les mots de passe doivent-être identiques`)}</LineMessage>
          )}
          <Footer>
            <Submit ref={submitPasswordButton}>{translate(`Mettre à jour`)}</Submit>
          </Footer>
          {displayPasswordUpdateSuccess && (
            <LineMessage>{translate(`Votre mot de passe a bien été mis à jour`)}</LineMessage>
          )}
        </form>
      </FormWrapper>
    </>
  )
}
