import React, { useContext, useState } from "react"
import UserContext from "../../../../utils/UserContext"
import { deleteFavori } from "../../../../services/auth"
import styled from "styled-components"
import { colors } from "../../../../utils/styles"

const Favori = styled.div`
  display: ${props => (props.deleted ? "inline-flex" : "inline-flex")};
  margin: 0.5rem 0.5rem;
  border: 1px solid ${colors.main};
  padding: 0.25rem 1.25rem 0.25rem 0.5rem;
  position: relative;
  cursor: pointer;

  &.empty {
    padding: 0;
    border: none;
    margin: 0;
  }

  .delete {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    background-color: ${colors.main};
    border-radius: 100%;
    z-index: 0;
  }

  span {
    position: relative;
    z-index: 1;
  }

  &.hidden {
    background: red;
  }
`

export default function RemoveFavoriButton({ toDelete, children }) {
  const { setProfile } = useContext(UserContext)
  const [deleted, setDeleted] = useState(false)

  const handleFavoriDelete = toDelete => {
    deleteFavori(toDelete.id).then(e => {
      const result = e.data
      if (!result.errors || result.errors.length === 0) {
        // Success
        setProfile(result.data.deleteFavori.user)
        setDeleted(true)
      } else {
        // Error
        setDeleted(false)
      }
    })
  }

  return (
    <Favori deleted={deleted} className={children ? "" : "empty"}>
      <span className="delete" onClick={() => handleFavoriDelete(toDelete)}>
        🗙
      </span>
      <span>{children}</span>
    </Favori>
  )
}
