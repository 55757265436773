import React, { useContext, useState } from "react"
import UserContext from "../../../utils/UserContext"
import {
  FormSubTitle,
  FormTitle,
  FormWrapper,
  PageHeader,
  PageTitle,
} from "../UserLayout"
import { FieldLine, FormGroup } from "../../form/form"
import styled from "styled-components"
import { Link } from "gatsby"
import RemoveFavoriButton from "./Favoris/RemoveFavoriButton"
import moment from "moment"
import SmallEvent from "../../events/SmallEvent"
import NotificationToggle from "./Notifications/NotificationToggle"
import EventFilters from "./Events/EventFilters"
import { getShortLocale, makeLocalePath } from "../../../utils/localization"
import I18nContext from "../../../utils/i18nContext"
import { useSiteContent } from "../../../hooks/useSiteContent"

const Favoris = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  min-width: 250px;
  max-width: 65%;
  margin-left: 1rem;
`

const FavoriLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

const PasDeFavori = styled.div`
  margin-left: 1rem;
  align-self: center;
  text-align: right;
`

export default function Preferences({ locale, events }) {
  const { profile } = useContext(UserContext)
  const [displayEventFilters, setDisplayEventsFilters] = useState(
    profile.preferences.notificationEvenements
  )
  const { translate } = useContext(I18nContext)
  const { lives, clubs, studios, records } = useSiteContent()
  const venues = [...lives, ...clubs, ...studios, ...records]

  const userLieuxFavoris = [
    ...profile.preferences.clubsFavoris,
    ...profile.preferences.livesFavoris,
    ...profile.preferences.recordsFavoris,
    ...profile.preferences.studiosFavoris,
  ]

  const lieuxFavoris = venues.filter(venue =>
    userLieuxFavoris.map(venue => venue.id).includes(venue.id)
  )

  const curateursFavoris = [...profile.preferences.curateursFavoris]
  // const evenementsSauvegardesPasses = [
  //   ...profile.preferences.evenementsSauvegardes,
  // ].filter(event =>
  //   moment("2021-10-13 00:00:00").isSameOrAfter(event.acf.horaires.fin)
  // )

  const evenementsSauvegardes = events.filter(event =>
    [...profile.preferences.evenementsSauvegardes]
      .map(event => event.id)
      .includes(event.id)
  )
  const evenementsSauvegardesFuturs = [...evenementsSauvegardes]
    .filter(event =>
      moment("2021-10-13 00:00:00").isBefore(event.acf.horaires.fin)
    )
    .filter(event => getShortLocale(event.locale.id) === locale)

  return (
    <>
      <PageHeader>
        <PageTitle>{translate(`Mes préférences`)}</PageTitle>
      </PageHeader>

      <FormWrapper>
        <FormGroup>
          <FormTitle>{translate(`Vos favoris`)}</FormTitle>

          <FieldLine alignItems={"start"}>
            <label>{translate(`Lieux favoris`)}</label>
            {lieuxFavoris.length > 0 ? (
              <Favoris>
                {lieuxFavoris.map((lieu, index) => (
                  <RemoveFavoriButton key={index} toDelete={lieu}>
                    <FavoriLink
                      to={makeLocalePath(lieu.type, lieu.slug, locale)}
                    >
                      {lieu.title}
                    </FavoriLink>
                  </RemoveFavoriButton>
                ))}
              </Favoris>
            ) : (
              <PasDeFavori>
                {translate(`Vous n'avez pas encore de lieu favori`)}
              </PasDeFavori>
            )}
          </FieldLine>
          <FieldLine alignItems={"start"}>
            <label>{translate(`Curateurs favoris`)}</label>
            {curateursFavoris.length > 0 ? (
              <Favoris>
                {curateursFavoris.map((curateur, index) => (
                  <RemoveFavoriButton key={index} toDelete={curateur}>
                    <FavoriLink
                      to={makeLocalePath(
                        "curator",
                        "?id=" + curateur.id,
                        locale
                      )}
                    >
                      {curateur.title}
                    </FavoriLink>
                  </RemoveFavoriButton>
                ))}
              </Favoris>
            ) : (
              <PasDeFavori>
                {translate(`Vous n'avez pas encore de curateur favori`)}
              </PasDeFavori>
            )}
          </FieldLine>
        </FormGroup>
        <FormGroup>
          <FormTitle>{translate(`Vos événements sauvegardés`)}</FormTitle>

          <FormSubTitle>{translate(`A venir`)}</FormSubTitle>
          {evenementsSauvegardesFuturs.length > 0 ? (
            <div>
              <div>
                {evenementsSauvegardesFuturs.map((event, index) => (
                  <SmallEvent key={index} event={event} locale={locale} />
                ))}
              </div>
            </div>
          ) : (
            <PasDeFavori>
              {translate(`Vous n'avez pas d'événements sauvegardés`)}
            </PasDeFavori>
          )}
        </FormGroup>
        <FormGroup>
          <FormTitle>{translate(`Vos alertes`)}</FormTitle>
          <NotificationToggle
            id="notificationLieuFavori"
            labelPrincipal={translate(`Notification lieu favori`)}
            labelDescription={translate(
              `Recevoir un email lorsqu'un de mes lieux favoris publie un événement`
            )}
          />
          <NotificationToggle
            id="notificationCurateurFavori"
            labelPrincipal={translate(`Notification curateur favori`)}
            labelDescription={translate(
              `Recevoir un email lorsqu'un de mes curateurs favoris publie un événement`
            )}
          />
          <NotificationToggle
            id="notificationEvenements"
            labelPrincipal={translate(`Notification événements`)}
            labelDescription={translate(
              `Recevoir un email lorsqu'un événement correspondant à ces critères est publié`
            )}
            toggleCallback={value => setDisplayEventsFilters(value)}
          />
          {(profile.notificationEvenenementFavori || displayEventFilters) && (
            <EventFilters />
          )}
        </FormGroup>
      </FormWrapper>
    </>
  )
}
